import React from "react";

export default function PasswordChangeSuccess() {
    return (
        <div className='w-full h-screen flex items-center justify-center bg-[#f7f7f7]'>
            <div className='text-center flex flex-col justify-center items-center'>
                <img
                    className="object-contain w-full max-w-[450px] mx-auto d-block"
                    src={process.env.REACT_APP_ASSETS_IMAGE + "freezemode-bg.jpeg"}
                    alt=""
                />
                <h1 className='mb-2 mt-4 font-bold text-3xl'>
                    Congratulations!
                </h1>
                <p className='font-medium text-md '>
                    Your password has been successfully updated. You can now log in with your new credentials. <br/> You
                    may close this tab if you're done. Thank You!
                </p>
            </div>
        </div>
    )
}