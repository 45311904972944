import Cookies from "js-cookie";
import AuthRepository from "../../repositories/AuthRepository";
import EncryptDecrypt from "../../services/encrypt-decrypt";
import RouteName from "../../services/routename";
import PopupUtility from "../../utils/popup-utility";
import GeneralUtility from "../../utils/general-utility";

const login = async (
    data,
    rememberme,
    navigate,
    // t,
    // setFormErrors,
    // setValidation,
    popup,
    setPopup,
    setCount,
) => {
    let res = await AuthRepository.getAuth(data);

    if (res.error === false) {
        let response = res.data.data;

        let user = {};
        user.userId = response.userId;
        user.merchantsId = response.merchantsId;
        user.merchantName = response.merchantName;
        user.merchantCode = response.merchantCode;
        user.merchantStatus = response.merchantStatus;
        user.merchantStatusName = response.merchantStatusName;
        user.email = response.email;
        user.emailOwner = response.ownerEmail;
        user.rating = response.rating;

        const buybackCompability = response.buybackCompability;

        if (response.merchantStatus === -2) {
            navigate(RouteName.merchantreject);
        } else if (
            response.merchantStatus === -1 ||
            response.merchantStatus === 0 ||
            response.merchantStatus === 1 ||
            response.merchantStatus === -3 ||
            response.merchantStatus === 5
        ) {
            window.location = RouteName.dashboard;
        } else if (
            response.merchantStatus === 2 ||
            response.merchantStatus === 3 ||
            response.merchantStatus === 4
        ) {
            navigate(RouteName.merchantapplication, {
                state: {merchantStatus: response},
            });
        }

        //set session on cookie and encrypt the data
        Cookies.set(
            "tokenMerchant",
            EncryptDecrypt.enc(response.auth?.token, {expires: 3})
        );
        Cookies.set(
            "refreshTokenMerchant",
            EncryptDecrypt.enc(response.auth.refreshToken, {expires: 3})
        );
        Cookies.set("userMerchant", EncryptDecrypt.enc(user), {expires: 3});
        Cookies.set("merchantPhoto", response.merchantPhoto, {
            expires: 3,
        });
        Cookies.set("hitRefreshTokenMerchant", 0, {expires: 3});
        Cookies.set(
            "buybackCompabilityMerchant",
            EncryptDecrypt.enc(response.buybackCompability),
            {expires: 3}
        );
        Cookies.set("balanceMerchant", EncryptDecrypt.enc(0), {
            expires: 3,
        });
        // set can buyback / buyback compability
        Cookies.set("buybackCompability", buybackCompability, {expires: 3});
        setCount(0);

        if (rememberme) {
            Cookies.set("emailMerchant", EncryptDecrypt.enc(data.email));
            Cookies.set("passwordMerchant", EncryptDecrypt.enc(data.password));
        } else {
            Cookies.remove("emailMerchant");
            Cookies.remove("passwordMerchant");
        }
    } else if (res.status === 403) {
        setPopup({
            status: !popup.status,
            message: res.message
        });
    } else {
        setCount(prev => prev + 1);
        setPopup({
            status: true,
            message: res.error_message[0]
        });
        // setFormErrors(res.error_message);
        // setValidation(res.error_message[0]);
    }
};

const forgotPassword = async (
    data,
    setError,
    setShowCaptcha,
    setEmail,
    setIsVerified
) => {
    let response = await AuthRepository.forgotPassword(data);

    if (response.error) {
        setEmail("");
        setError(response.error_message);
        setShowCaptcha(false);
        setIsVerified(false);
    } else {
        PopupUtility.success(response.message, "Check Your Inbox Email");
        window.location = RouteName.login;
        setIsVerified(false);
    }
};

const resetPassword = async (data, type) => {
    let response;
    if (type === "web") {
        response = await AuthRepository.resetPassword(data);
    } else {
        response = await AuthRepository.resetPasswordCustomer(data);
    }

    if (response.error) {
        PopupUtility.responseValidate(
            "Password do not match. Please try again.",
            response.message
        );
    } else {
        PopupUtility.success(response.message, "Password Changed Successfully!");
        if (type === "web") {
            window.location = RouteName.login;
        } else {
            window.location = RouteName.passwordchangesuccess;
        }
    }
};

const getUser = () => {
    // get data user from cookie
    let data = Cookies.get("userMerchant");
    if (data) {
        let user = EncryptDecrypt.dec(data);
        return user;
    } else {
        return "";
    }
};

const getPhoto = () => {
    // get data user from cookie
    let data = Cookies.get("merchantPhoto");
    if (data && data !== "text") {
        return data;
    } else {
        return "";
    }
};

const getStatus = () => {
    let data = Cookies.get("userMerchant");
    if (data) {
        let user = EncryptDecrypt.dec(data);
        if (
            user.merchantStatus === -1 ||
            user.merchantStatus === 0 ||
            user.merchantStatus === 1 ||
            user.merchantStatus === 4 ||
            user.merchantStatus === -3 ||
            user.merchantStatus === 5
        ) {
            return 0;
        } else if (
            user.merchantStatus === 2 ||
            user.merchantStatus === 3 ||
            user.merchantStatus === -2
        ) {
            return 1;
        }
    } else {
        return 1;
    }
};

const checkBuybackCompability = () => {
    let data = Cookies.get("buybackCompabilityMerchant");
    if (data) {
        let buyback = EncryptDecrypt.dec(data);
        if (buyback === true) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const getBalance = () => {
    // get data user from cookie
    let balance = Cookies.get("balanceMerchant");
    if (balance) {
        let data = EncryptDecrypt.dec(balance);
        let comas = GeneralUtility.addCommas(data);
        return comas;
    } else {
        return 0;
    }
};

const logout = () => {
    removeAllInternalStorage();
    window.location = RouteName.login;
};

const removeAllInternalStorage = () => {
    // remote data from cookie
    Cookies.remove("tokenMerchant");
    Cookies.remove("refreshTokenMerchant");
    Cookies.remove("userMerchant");
    Cookies.remove("merchantPhoto");
    Cookies.remove("hitRefreshTokenMerchant");
    Cookies.remove("buybackCompabilityMerchant");
    Cookies.remove("balanceMerchant");
    Cookies.remove("language");
    Cookies.remove("buybackCompability");
    Cookies.remove("status_maintenance");
    localStorage.removeItem("roleButton");
};

const UseAuth = {
    login,
    getUser,
    getStatus,
    getPhoto,
    logout,
    forgotPassword,
    resetPassword,
    checkBuybackCompability,
    getBalance,
    removeAllInternalStorage
};

export default UseAuth;
